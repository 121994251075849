<template>
  <div class="flex justify-between">
    <div class="w-4/12">
      <div class="mb-2">
        <label class="block font-medium text-sm text-gray-500 mb-1" for="name">
          Type
        </label>
        <div class="flex space-x-2">
          <div class="py-0.5 px-2 border-2 border-blue-400 rounded text-blue-400 cursor-pointer">
            <font-awesome-icon icon="link"/> Url
          </div>
          <div class="py-0.5 px-2 border-2 border-gray-300 rounded text-gray-300 cursor-not-allowed">
            <font-awesome-icon icon="address-card"/> Contact card
          </div>
          <div class="py-0.5 px-2 border-2 border-gray-300 rounded text-gray-300 cursor-not-allowed">
            <font-awesome-icon icon="list"/> Link page
          </div>
        </div>
      </div>
      <form @submit.prevent="update" class="space-y-6 mt-5">
        <div>
          <label class="block font-medium text-sm text-gray-500" for="name">
            Naam
          </label>
          <input v-model="data.friendly_name"
                 class="p-2 rounded-md shadow-sm bg-white border border-gray-300 text-gray-400 block mt-1 w-full"
                 type="text"
                 id="name" name="name" required>
        </div>
        <div>
          <label class="block font-medium text-sm text-gray-500" for="url">
            Redirect (naar)
          </label>
          <input v-model="data.target_url"
                 class="p-2 rounded-md shadow-sm bg-white border border-gray-300 text-gray-400 block mt-1 w-full"
                 type="url"
                 id="url" name="url" required placeholder="https://" @input="handleInput">
        </div>
        <div>
          <label class="block font-medium text-sm text-gray-500">
            Achtergrondkleur
          </label>
          <color-picker v-model:pureColor="pureColor"/>
        </div>
        <div class="flex items-center justify-end">
          <button type="submit"
                  class="inline-flex items-center px-4 py-2 bg-gray-900 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150 ml-3">
            Aanmaken
          </button>
        </div>
        <div v-if="errors" class="text-red-500 py-2 font-semibold">
          <span>{{ errors.message }}</span>
        </div>
      </form>
    </div>
    <div class="w-4/12 flex flex-col justify-end">
      <div class="qr w-[400px] h-[400px] bg-gray-400 rounded flex items-center justify-center">
        <img v-if="qrCode" :src="'data:image/png;base64,' + qrCode" alt="QR Code Preview" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { mapActions, mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth.js';
import { toast } from 'vue3-toastify';
import { ColorPicker } from "vue3-colorpicker";
import axios from 'axios';

export default {
  components: { ColorPicker },
  setup() {
    const pureColor = ref("#ffffff"); // Standaardkleur wit
    const data = ref({
      friendly_name: null,
      target_url: 'https://',
    });
    const qrCode = ref(null);
    const errors = ref({});
    let debounceTimer = null;

    // Debounced API call on changes
    const updateQRCode = () => {
      if (debounceTimer) clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        try {
          const rgb = parseRGB(pureColor.value);
          const response = await axios.post('/api/generate-qr', {
            data: data.value.target_url,
            size: 300,
            margin: 1,
            color: [0, 0, 0],
            bgColor: rgb
          });
          qrCode.value = response.data.qrCode;
        } catch (error) {
          errors.value = error.response ? error.response.data : error.message;
          console.error('Error generating QR code:', error);
        }
      }, 500);
    };

    // Watchers for input changes
    watch(pureColor, updateQRCode);
    watch(() => data.value.target_url, updateQRCode);

    // Parse color to RGB
    const parseRGB = (color) => {
      let rgb = [255, 255, 255]; // Default to white
      if (color.startsWith('#')) {
        rgb = color.match(/\w\w/g).map(x => parseInt(x, 16));
      } else if (color.startsWith('rgb')) {
        rgb = color.match(/\d+/g).map(Number);
      }
      return rgb;
    };

    return { pureColor, data, qrCode, errors };
  },
  methods: {
    ...mapActions(useAuthStore, ['attempt_user']),
    handleInput(event) {
      this.data.target_url = event.target.value;
    },
    update() {
      axios.post('/api/tags', this.data)
        .then((response) => {
          this.attempt_user();
          this.data.friendly_name = null;
          this.data.target_url = null;

          this.$router.push({ name: 'Tags' });

          setTimeout(() => {
            toast.success('Tag aangemaakt!', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }, 50);
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
    }
  }
};
</script>
