<template>
  <nav class="bg-white border-b border-gray-200 p-4 mb-4">
    <div class="container mx-auto flex flex-col items-center">
      <router-link :to="{ name: 'Home' }" class=" max-w-[180px] pb-4 md:pb-0 mr-2">
        <Logo/>
      </router-link>
      <ul class="list flex w-full items-center text-gray-500 text-sm font-semibold">
        <li>
          <router-link :to="{ name: 'Home' }" class="p-2 hover:text-black">
            <span>Dashboard</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Tags' }" class="p-2 hover:text-black ">
            <span>Mijn tags</span>
          </router-link>
        </li>
        <li class="ml-auto">
          <router-link :to="{ name: 'Tags/new' }" class="px-4 py-3 mr-2 text-[15px] hover:bg-blue-500 hover:border-blue-500 hover:text-white border-2 rounded-lg ">
            <span><font-awesome-icon icon="square-plus"/> Nieuwe tag</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'User' }" class="p-2 text-xl hover:text-black ">
            <span> <font-awesome-icon icon="circle-user"/></span>
          </router-link>
        </li>
        <li>
          <button @click="logoutUser" class="p-2 text-xl hover:text-black ">
            <span><font-awesome-icon icon="right-from-bracket"/></span>
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script>
import {mapActions, mapStores} from 'pinia'
import {useAuthStore} from '@/stores/auth.js'
import Logo from "@/components/Logo.vue";

export default {
  components: {Logo},
  computed: {
    ...mapStores(useAuthStore),
    currentUser() {
      return this.authStore?.currentUser
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['logout']),
    logoutUser() {
      this.logout()
        .then(() => {
          this.$router.push({name: 'Login'})
        })
    },
  },
}
</script>
