import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import App from '@/layouts/App.vue';
import '../css/app.css';
import axios from 'axios';

import FontAwesomeIcon from './fontawesome'

import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;
window.axios.defaults.withXSRFToken = true;

const pinia = createPinia()
const app = createApp(App)
  .use(pinia)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(Vue3ColorPicker)
  .use(Vue3Toastify, {
    autoClose: 3000,
  });

const userStore = useAuthStore()
userStore.attempt_user()
  .catch((error) => {
    console.log('Please login.')
  })
  .finally(() => {
    app.use(router)
      .mount('#app');
  })
