<template>
  <div>
    Page Not found
  </div>
</template>

<script>
export default {

}
</script>
