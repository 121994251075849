<template>
  <div class="p-5 bg-white shadow-md rounded-lg">
    <h3 class="text-lg font-semibold">Tag aanmaken</h3>

    <hr class="my-5">

    <TagGenerator></TagGenerator>


  </div>
<!--  <div class="container px-2 md:px-0 mx-auto space-y-2">-->
<!--    <div class="md:flex md:justify-between md:space-x-2 py-6">-->
<!--      <div class="md:w-1/3 pb-4">-->
<!--        <h3 class="text-xl">Tag</h3>-->
<!--        <p class="mt-1 text-sm text-gray-600">-->
<!--          Maak hier een nieuwe tag aan.-->
<!--        </p>-->
<!--      </div>-->
<!--      <TagCreate class="md:w-2/3 p-4 bg-white shadow-md rounded-lg"></TagCreate>-->
<!--    </div>-->
<!--  </div>-->


</template>

<script>
import TwoFactoreAuthentication from '@/components/user/TwoFactoreAuthentication.vue'
// import ProfileUpdate from '@/components/user/ProfileUpdate.vue'
// import PasswordUpdate from '@/components/user/PasswordUpdate.vue'
import TagCreate from '@/components/tag/TagCreate.vue';
import TagGenerator from "@/components/tag/TagGenerator.vue";

export default {
  components: {
    TagGenerator,
    TagCreate
  }
}
</script>
