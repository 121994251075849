<template>
  <div class="p-5 bg-white shadow-md rounded-lg">
    <h3 class="text-lg font-semibold">Dashboard</h3>

    <hr class="my-5">

    <h2 class="mb-2 text-lg font-semibold text-gray-900">
      {{ currentUser?.business_name }}
    </h2>



  </div>
</template>

<script>
import {mapActions, mapStores} from "pinia";
import {useAuthStore} from "@/stores/auth.js";

export default {
  computed: {
    ...mapStores(useAuthStore),
    currentUser() {
      return this.authStore?.currentUser
    },
  }
}
</script>


