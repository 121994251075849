<template>
  <div class="p-5 bg-white shadow-md rounded-lg">
    <h3 class="text-lg font-semibold">
      Jouw tag{{ tags.length === 1 ? '' : 's' }}
      <span class="text-gray-400">({{ tags.length }})</span>:
    </h3>

    <hr class="my-4">


    <ul class="max-w-2wxl divide-y divide-gray-200">

      <li class="pb-3 sm:pb-4 py-5" v-for="tag in tags" :key="tag.id">
        <div class="flex items-center space-x-4 space-y-4">
          <div class="flex-shrink-0">
            <img class="w-12 h-12" :src="`data:image/png;base64,${tag.qr_code}`" />
          </div>

          <div class="flex-0 min-w-0">
            <p class="text-sm font-medium text-gray-900 truncate">
              {{ tag.name }}
            </p>
            <p class="text-sm text-gray-500 truncate">
              {{ tag.url }} -> {{ tag.target_url }}
            </p>
          </div>

          <div class="flex-1 ml-auto">
            type: url
          </div>

          <div class="inline-flex items-center text-base font-semibold text-gray-900">
            Triggers: {{ tag.events_count }}
          </div>
        </div>
      </li>

    </ul>
  </div>
</template>

<script>
import {mapActions} from "pinia";
import {useAuthStore} from "@/stores/auth.js";

export default {
  data() {
    return {
      tags: []
    }
  },
  mounted() {
    this.fetchTags();
  },
  methods: {
    ...mapActions(useAuthStore, ['attempt_user']),
    fetchTags() {
      axios.get('/api/tags')
        .then((response) => {
          this.tags = response.data.data
          console.log(response.data.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>


