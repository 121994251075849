<template>
  <main>
    <navigation></navigation>
    <div class="container mx-auto">
      <router-view></router-view>

      <div class="md:flex text-xs text-gray-500 py-8 px-2 md:px-0 break-words space-x-4">
        &copy; TagsUp - By&nbsp;<a href="https://bobix.nl" target="_blank" class="underline">Bobix</a>
      </div>
    </div>
  </main>
</template>

<script>
import Navigation from '@/components/Navigation.vue'

export default {
  components: {
    Navigation,
  },
}
</script>
