<template>
  <router-link :to="{ name: 'Login' }">
      <svg width="100%" height="100%" viewBox="0 0 1930 458" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
           style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g transform="matrix(4.22613,0,0,4.22613,-4017.89,-177.284)">
          <path
            d="M1019.02,67.865C1019.87,67.865 1020.59,68.16 1021.18,68.751C1021.77,69.341 1022.07,70.062 1022.07,70.913L1022.07,86.431C1022.07,87.268 1021.77,87.982 1021.18,88.572C1020.59,89.163 1019.88,89.458 1019.04,89.458C1019.03,89.458 1019.02,89.458 1019.02,89.458L999.064,89.458L999.064,146.129C999.064,146.966 998.769,147.68 998.179,148.27C997.589,148.86 996.875,149.155 996.038,149.155C996.031,149.155 996.023,149.155 996.016,149.155L976.775,149.155C976.768,149.155 976.76,149.155 976.753,149.155C975.916,149.155 975.202,148.86 974.612,148.27C974.022,147.68 973.726,146.966 973.726,146.129L973.726,89.458L953.774,89.458C953.767,89.458 953.759,89.458 953.752,89.458C952.915,89.458 952.201,89.163 951.611,88.572C951.021,87.982 950.726,87.268 950.726,86.431L950.726,70.913C950.726,70.062 951.021,69.341 951.611,68.751C952.201,68.16 952.922,67.865 953.774,67.865L1019.02,67.865Z"
            style="fill:rgb(0,166,255);"/>
      </g>
        <g transform="matrix(4.22613,0,0,4.22613,-3988.89,-177.284)">
          <path
            d="M1007.54,149.155C1006.86,149.155 1006.27,148.908 1005.77,148.412C1005.28,147.916 1005.03,147.326 1005.03,146.644C1005.03,146.32 1005.08,146.02 1005.17,145.744L1031.72,71.414C1031.96,70.577 1032.48,69.776 1033.27,69.012C1034.05,68.247 1035.16,67.865 1036.6,67.865L1057.81,67.865C1059.24,67.865 1060.35,68.247 1061.14,69.012C1061.92,69.776 1062.44,70.577 1062.69,71.414L1089.24,145.744C1089.33,146.02 1089.38,146.32 1089.38,146.644C1089.38,147.326 1089.13,147.916 1088.63,148.412C1088.14,148.908 1087.55,149.155 1086.87,149.155L1068.97,149.155C1067.63,149.155 1066.66,148.848 1066.05,148.234C1065.45,147.619 1065.03,147.031 1064.82,146.47L1061.2,136.759L1033.21,136.759L1029.59,146.47C1029.39,147.031 1029,147.619 1028.41,148.234C1027.83,148.848 1026.84,149.155 1025.43,149.155L1007.54,149.155ZM1038.54,117.053L1055.87,117.053L1047.16,90.924L1038.54,117.053Z"
            style="fill:rgb(0,166,255);fill-rule:nonzero;"/>
      </g>
        <g transform="matrix(4.22613,0,0,4.22613,-3965.89,-177.284)">
          <path
            d="M1121.16,150.317C1113.56,150.317 1107.01,149.13 1101.5,146.757C1096,144.383 1091.7,140.843 1088.62,136.135C1085.53,131.426 1083.84,125.581 1083.55,118.599C1083.47,115.367 1083.43,111.878 1083.43,108.133C1083.43,104.388 1083.47,100.836 1083.55,97.478C1083.84,90.733 1085.55,85.088 1088.66,80.545C1091.78,76.001 1096.13,72.559 1101.7,70.217C1107.28,67.875 1113.76,66.704 1121.16,66.704C1127.13,66.704 1132.45,67.403 1137.13,68.801C1141.8,70.2 1145.78,72.095 1149.06,74.488C1152.34,76.881 1154.84,79.576 1156.57,82.574C1158.3,85.571 1159.2,88.638 1159.28,91.773C1159.29,92.47 1159.05,93.063 1158.56,93.551C1158.06,94.04 1157.46,94.284 1156.75,94.284L1135.41,94.284C1134.59,94.284 1133.95,94.157 1133.48,93.903C1133.01,93.649 1132.59,93.285 1132.22,92.811C1131.82,92.047 1131.22,91.159 1130.41,90.147C1129.59,89.136 1128.46,88.26 1127.01,87.52C1125.55,86.78 1123.6,86.409 1121.16,86.409C1117.54,86.409 1114.73,87.343 1112.73,89.211C1110.73,91.079 1109.64,94.028 1109.45,98.059C1109.22,104.586 1109.22,111.239 1109.45,118.018C1109.64,122.475 1110.79,125.688 1112.92,127.657C1115.05,129.626 1117.92,130.611 1121.54,130.611C1123.97,130.611 1126.12,130.228 1128,129.461C1129.87,128.694 1131.36,127.47 1132.44,125.788C1133.52,124.107 1134.07,121.957 1134.07,119.339L1134.07,118.134L1125.84,118.134C1125,118.134 1124.29,117.836 1123.7,117.238C1123.11,116.641 1122.82,115.923 1122.82,115.086L1122.82,104.308C1122.82,103.456 1123.11,102.735 1123.7,102.145C1124.29,101.555 1125,101.259 1125.84,101.259L1156.45,101.259C1157.3,101.259 1158.02,101.555 1158.61,102.145C1159.2,102.735 1159.5,103.456 1159.5,104.308L1159.5,118.49C1159.5,125.313 1157.88,131.085 1154.66,135.808C1151.43,140.53 1146.94,144.129 1141.19,146.604C1135.44,149.079 1128.76,150.317 1121.16,150.317Z"
            style="fill:rgb(0,166,255);fill-rule:nonzero;"/>
      </g>
        <g transform="matrix(4.22613,0,0,4.22613,-3936.89,-177.284)">
          <path
            d="M1195.07,150.317C1186.91,150.317 1180.16,149.185 1174.81,146.92C1169.46,144.655 1165.43,141.685 1162.73,138.007C1160.03,134.33 1158.61,130.427 1158.45,126.3C1158.45,125.618 1158.7,125.028 1159.2,124.532C1159.69,124.037 1160.29,123.789 1160.99,123.789L1179.35,123.789C1180.5,123.789 1181.38,123.974 1182,124.344C1182.61,124.714 1183.24,125.209 1183.88,125.828C1184.57,126.675 1185.4,127.471 1186.36,128.216C1187.33,128.961 1188.53,129.547 1189.98,129.972C1191.42,130.398 1193.12,130.611 1195.07,130.611C1198.63,130.611 1201.38,130.118 1203.33,129.13C1205.27,128.143 1206.24,126.805 1206.24,125.117C1206.24,123.781 1205.71,122.684 1204.64,121.825C1203.58,120.966 1201.82,120.191 1199.36,119.499C1196.9,118.807 1193.55,118.098 1189.32,117.372C1183.32,116.347 1178.16,114.838 1173.85,112.847C1169.53,110.856 1166.23,108.175 1163.93,104.805C1161.64,101.435 1160.49,97.275 1160.49,92.325C1160.49,87.307 1161.9,82.869 1164.71,79.01C1167.53,75.151 1171.46,72.135 1176.52,69.963C1181.57,67.79 1187.5,66.704 1194.3,66.704C1199.87,66.704 1204.8,67.433 1209.09,68.892C1213.38,70.351 1216.99,72.263 1219.93,74.63C1222.87,76.996 1225.1,79.532 1226.64,82.24C1228.18,84.947 1228.99,87.549 1229.07,90.046C1229.07,90.713 1228.83,91.299 1228.34,91.802C1227.86,92.305 1227.29,92.557 1226.63,92.557L1207.32,92.557C1206.37,92.557 1205.57,92.402 1204.92,92.093C1204.28,91.783 1203.66,91.289 1203.07,90.612C1202.66,89.572 1201.7,88.611 1200.18,87.73C1198.65,86.85 1196.69,86.409 1194.3,86.409C1191.65,86.409 1189.63,86.856 1188.22,87.749C1186.82,88.641 1186.11,89.947 1186.11,91.664C1186.11,92.811 1186.55,93.821 1187.42,94.695C1188.29,95.568 1189.81,96.335 1191.97,96.995C1194.13,97.656 1197.14,98.342 1201.01,99.053C1208.38,100.156 1214.33,101.716 1218.85,103.731C1223.37,105.746 1226.66,108.388 1228.74,111.657C1230.82,114.925 1231.86,119.003 1231.86,123.89C1231.86,129.368 1230.27,134.082 1227.09,138.032C1223.91,141.983 1219.56,145.018 1214.03,147.138C1208.5,149.257 1202.18,150.317 1195.07,150.317Z"
            style="fill:rgb(0,166,255);fill-rule:nonzero;"/>
      </g>
        <g transform="matrix(4.22613,0,0,4.22613,-3913.89,-177.284)">
          <path
            d="M1304.85,83.637C1304.86,83.72 1304.86,83.804 1304.86,83.89L1304.86,118.345C1304.86,125.666 1303.35,131.695 1300.33,136.432C1297.31,141.169 1293.09,144.668 1287.67,146.927C1282.25,149.187 1275.95,150.317 1268.79,150.317C1261.57,150.317 1255.24,149.187 1249.78,146.927C1244.32,144.668 1240.08,141.169 1237.05,136.432C1234.02,131.695 1232.51,125.666 1232.51,118.345L1232.51,80.61C1232.51,79.758 1232.8,79.037 1233.39,78.447C1233.83,78.011 1234.33,77.736 1234.9,77.622C1235.52,77.478 1236.28,78.06 1236.66,78.313C1240.02,80.546 1256.05,91.197 1256.58,91.727C1257.17,92.317 1257.47,93.038 1257.47,93.89L1257.47,118.033C1257.47,121.986 1258.43,124.976 1260.36,127.004C1262.28,129.031 1265.06,130.045 1268.69,130.045C1272.31,130.045 1275.09,129.031 1277.01,127.004C1278.94,124.976 1279.9,121.986 1279.9,118.033L1279.9,83.89C1279.9,83.764 1279.91,83.641 1279.92,83.521L1279.92,66.531L1271.43,66.531C1271.43,66.531 1271.04,66.532 1271.04,66.188C1271.04,65.844 1271.35,65.425 1271.35,65.425L1291.74,42.405C1291.74,42.405 1292.09,41.95 1292.41,41.95C1292.74,41.95 1293.09,42.442 1293.09,42.442L1313.26,65.516C1313.26,65.516 1313.63,65.984 1313.63,66.153C1313.63,66.575 1313.21,66.531 1313.21,66.531L1304.85,66.531L1304.85,83.637Z"
            style="fill:rgb(0,166,255);"/>
      </g>
        <g transform="matrix(4.22613,0,0,4.22613,-3918.89,-177.284)">
          <path
            d="M1316.48,149.155C1315.64,149.155 1314.92,148.86 1314.33,148.27C1313.74,147.68 1313.45,146.966 1313.45,146.129L1313.45,70.913C1313.45,70.062 1313.74,69.341 1314.33,68.751C1314.92,68.16 1315.64,67.865 1316.48,67.865L1350.36,67.865C1357.1,67.865 1362.96,68.869 1367.96,70.877C1372.95,72.885 1376.83,75.937 1379.61,80.033C1382.38,84.129 1383.76,89.281 1383.76,95.489C1383.76,101.697 1382.38,106.786 1379.61,110.757C1376.83,114.727 1372.95,117.653 1367.96,119.535C1362.96,121.418 1357.1,122.359 1350.36,122.359L1339.35,122.359L1339.35,146.129C1339.35,146.966 1339.06,147.68 1338.47,148.27C1337.88,148.86 1337.15,149.155 1336.3,149.155L1316.48,149.155ZM1338.88,103.597L1349.78,103.597C1352.04,103.597 1353.96,102.928 1355.52,101.59C1357.08,100.252 1357.86,98.155 1357.86,95.301C1357.86,93.075 1357.25,91.088 1356.04,89.342C1354.82,87.595 1352.74,86.722 1349.78,86.722L1338.88,86.722L1338.88,103.597Z"
            style="fill:rgb(0,166,255);fill-rule:nonzero;"/>
      </g>
  </svg>
  </router-link>
</template>
